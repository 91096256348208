<template>
  <div class="space-y-4">
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4">
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Item Type Configuration Main Page',
            })
          "
          icon="file-document"
          text="Inventory"
        ></asom-button>
        <asom-button
          v-if="canEditInventoryNotifications"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Item Type Threshold Configuration Main Page',
            })
          "
          icon="align-left"
          text="Item Type Threshold"
        ></asom-button>
        <asom-button
        v-if="canEditFirstAidBox"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'First Aid Box Configuration Main Page',
            })
          "
          icon="add-r"
          text="First Aid Box"
        ></asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Location Configuration Main Page',
            })
          "
          icon="nametag"
          text="Location"
        ></asom-button>
        <!-- <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Notification Configuration Main Page',
            })
          "
          icon="notifications"
          text="Notifications"
        ></asom-button> -->
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <h3 class="subheader">
        Configuration Status
      </h3>
      <br />
      <dl class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 flex flex-nowrap content-start space-x-1"
            >
              <asom-icon :icon="'file-document'" size="sm" />
              <span>Inventory Type</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ itemTypes.totalCount }} Types
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ itemTypes.generalCount }}</strong> in <strong>General</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ itemTypes.smartCardCount }}</strong> in <strong>Smart Card</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ itemTypes.trackedItemsCount }}</strong> in <strong>Tracked Items</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ itemTypes.firstAidCount }}</strong> in <strong>First Aid</strong>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 flex flex-nowrap content-start space-x-1"
            >
              <asom-icon :icon="'inbox'" size="sm" />
              <span>Locations</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              <p>{{ locations.totalCount }} Locations</p>
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ locations.primaryCount }}</strong> - <strong>Primary Locations</strong>
            </li>
          </ul>
        </aside>
        <aside
          v-if="canViewInventoryNotifications"
          class="rounded-lg bg-white overflow-hidden shadow"
        >
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 flex flex-nowrap content-start space-x-1"
            >
              <asom-icon :icon="'calendar-next'" size="sm" />
              <span>Delivery Elapse Days</span>
            </dt>
            <dd
              class="text-3xl font-semibold text-gray-900 text-left grid grid-flow-col justify-items-stretch"
            >
              <p>{{ deliveryElapseDays }} Days</p>
              <div
                v-if="canEditInventoryNotifications"
                class="justify-self-end"
                @click="editDeliveryElapseClicked"
              >
                <asom-icon :icon="'external'" size="md" class="text-blue-500" />
              </div>
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <p>
                No. of days after intended delivery date allowed before the order is deemed overdue
              </p>
            </li>
          </ul>
        </aside>
        <aside
          v-if="canViewInventoryNotifications"
          class="rounded-lg bg-white overflow-hidden shadow"
        >
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 flex flex-nowrap content-start space-x-1"
            >
              <asom-icon :icon="'timelapse'" size="sm" />
              <span>Threshold no. of days before expiry</span>
            </dt>
            <dd
              class="text-3xl font-semibold text-gray-900 text-left grid grid-flow-col justify-items-stretch"
            >
              <p>{{ thresholdExpireInDays }} Days</p>
              <div
                v-if="canEditInventoryNotifications"
                class="justify-self-end"
                @click="editThresholdExpireClicked"
              >
                <asom-icon :icon="'external'" size="md" class="text-blue-500" />
              </div>
            </dd>
          </div>
        </aside>
      </dl>
    </article>
    <asom-modal
      v-model="showDeliveryElapse"
      title="Edit Delivery Elapse Days"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="deliveryElapseError"
        :error-message="deliveryElapseError"
      />
      <asom-form-field
        required
        label="Days"
        :state="inputStates('deliveryElapseDays')"
        error="Delivery Elapse Days is required"
      >
        <asom-input-text
          type="number"
          min="0"
          v-model.number="deliveryElapseDays"
          :state="inputStates('deliveryElapseDays')"
      /></asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="deliveryElapseEdit"
          :disabled="isSubmittingDeliveryElapseEdit"
          :loading="isSubmittingDeliveryElapseEdit"
          text="Confirm"
        />
        <asom-button
          @click="toggleDeliveryElapseModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
    <asom-modal
      v-model="showThresholdExpire"
      title="Edit Threshold Expire Days"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="thresholdExpireError"
        :error-message="thresholdExpireError"
      />
      <asom-form-field
        required
        label="Days"
        :state="inputStates('thresholdExpireInDays')"
        error="Threshold Expire Days is required"
      >
        <asom-input-text
          type="number"
          min="0"
          v-model.number="thresholdExpireInDays"
          :state="inputStates('thresholdExpireInDays')"
      /></asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="thresholdExpireEdit"
          :disabled="isSubmittingThresholdExpireEdit"
          :loading="isSubmittingThresholdExpireEdit"
          text="Confirm"
        />
        <asom-button
          @click="toggleThresholdExpireModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  getConfigurationMainPageData,
  editDeliveryElapseDays,
  editExpireThreshold,
} from "../../../services/inventory.service";

export default {
  name: "ConfiguartionMainPage",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      error: null,
      itemTypes: {
        totalCount: null,
        generalCount: null,
        smartCardCount: null,
        trackedItemsCount: null,
        firstAidCount: null,
      },
      locations: {
        totalCount: null,
        primaryCount: null,
        secondaryCount: null,
      },
      deliveryElapseDays: null,
      showDeliveryElapse: false,
      deliveryElapseError: null,
      isSubmittingDeliveryElapseEdit: false,
      thresholdExpireInDays: null,
      showThresholdExpire: false,
      thresholdExpireError: null,
      isSubmittingThresholdExpireEdit: false,
    };
  },
  validations() {
    return {
      deliveryElapseDays: { required },
      thresholdExpireInDays: { required },
    };
  },
  computed: {
    ...mapGetters({
      canViewDigitalRecords: "auth/canViewDigitalRecords",
      canEditFirstAidBox: "auth/canEditFirstAidBox",
      canEditInventoryNotifications: "auth/canEditInventoryNotifications",
      canViewInventoryNotifications: "auth/canViewInventoryNotifications",
      selectedLineId: "selectedStation/lineId"
    }),
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    async getPageData() {
      const resp = await getConfigurationMainPageData({ lineId: this.selectedLineId });
      if (resp.success) {
        this.setData(get(resp.payload, "data", {}));
        this.error = null;
      } else {
        this.setData({});
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    setData(data) {
      this.itemTypes.totalCount = get(data, "inventoryTypeCount", "-");
      this.itemTypes.generalCount = get(data, "generalInventoryTypeCount", "-");
      this.itemTypes.smartCardCount = get(
        data,
        "smartCardInventoryTypeCount",
        "-"
      );
      this.itemTypes.trackedItemsCount = get(
        data,
        "serializedTagInventoryTypeCount",
        "-"
      );
      this.itemTypes.firstAidCount = get(
        data,
        "firstAidInventoryTypeCount",
        "-"
      );
      this.locations.totalCount = get(data, "locationCount", "-");
      this.locations.primaryCount = get(data, "primaryLocationCount", "-");
      this.locations.secondaryCount = get(data, "secondaryLocationCount", "-");
      this.deliveryElapseDays = get(data, "deliveryElapseInDays", 0);
      this.thresholdExpireInDays = get(data, "expireThresholdInDays", 0);
    },
    editDeliveryElapseClicked() {
      this.toggleDeliveryElapseModal(true);
    },
    toggleDeliveryElapseModal(value) {
      this.deliveryElapseError = null;
      this.showDeliveryElapse = value;
    },
    async deliveryElapseEdit() {
      this.deliveryElapseError = null;
      this.v$.deliveryElapseDays.$reset();
      this.v$.deliveryElapseDays.$touch();
      if (!this.v$.deliveryElapseDays.$invalid) {
        this.isSubmittingDeliveryElapseEdit = true;
        const result = await editDeliveryElapseDays({
          deliveryElapseInDays: this.deliveryElapseDays,
          lineId: this.selectedLineId
        });
        if (result.success) {
          this.isSubmittingDeliveryElapseEdit = false;
          this.toggleDeliveryElapseModal(false);
          this.getPageData();
        } else {
          this.isSubmittingDeliveryElapseEdit = false;
          this.deliveryElapseError = result.payload;
          this.$scrollTop();
        }
      } else {
        this.deliveryElapseError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    editThresholdExpireClicked() {
      this.toggleThresholdExpireModal(true);
    },
    toggleThresholdExpireModal(value) {
      this.thresholdExpireError = null;
      this.showThresholdExpire = value;
    },
    async thresholdExpireEdit() {
      this.thresholdExpireError = null;
      this.v$.thresholdExpireInDays.$reset();
      this.v$.thresholdExpireInDays.$touch();
      if (!this.v$.thresholdExpireInDays.$invalid) {
        this.isSubmittingThresholdExpireEdit = true;
        const result = await editExpireThreshold({
          expiredThresholdInDays: this.thresholdExpireInDays,
          lineId: this.selectedLineId
        });
        if (result.success) {
          this.isSubmittingThresholdExpireEdit = false;
          this.toggleThresholdExpireModal(false);
          this.getPageData();
        } else {
          this.isSubmittingThresholdExpireEdit = false;
          this.thresholdExpireError = result.payload;
          this.$scrollTop();
        }
      } else {
        this.thresholdExpireError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
  },
};
</script>
